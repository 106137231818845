<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M20.5 10.5c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10zm-1 0a9 9 0 1 0-18 0 9 9 0 0 0 18 0z"
    />
    <path d="M10 1v9.5a.5.5 0 0 0 1 0V1a.5.5 0 0 0-1 0z" />
    <path d="M20 10h-9.5a.5.5 0 0 0 0 1H20a.5.5 0 1 0 0-1z" />
    <path d="M15.255 18.03l-4.318-7.773a.5.5 0 0 0-.874.486l4.318 7.773a.5.5 0 1 0 .874-.486z" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
